import { createStore, combineReducers } from 'redux';
import visibleSidebar from './reducers/visibleSidebar';
import isMobile from './reducers/isMobile';

const reducer = combineReducers({
    visibleSidebar,
    isMobile
});

const store = createStore(reducer);

export default store;