import React from "react";
import { Switch, Redirect } from "react-router-dom";

import Route from "./Route";

import NotFound from "../pages/NotFound";
import Root from "../pages/Root";

import '../styles/global.scss';
import Flow from "../pages/Flow";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/flow" component={Flow} />
      <Route exact path="/404" component={NotFound} is404 />
      <Route exact path="/" component={Root} />

      <Redirect to="/404" />
    </Switch>
  );
}

