import React, { Component } from 'react';
import KLINIKAPP_REGISTERED from '../../images/KLINIKAPP_REGISTERED.png';
import LICENSE_CHECK_FILLING from '../../images/LICENSE_CHECK_FILLING.png';
import LOGO_CLOUD_ICE from '../../images/LOGO_CLOUD_ICE.png';


class Flow extends Component {

  render() {
    return (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', paddingTop: 70, alignItems: 'center' }}>
            <img src={KLINIKAPP_REGISTERED} alt="Logo" style={{ width: 250 }}/>
            <img src={LICENSE_CHECK_FILLING} alt="Logo" style={{ width: 100, marginTop: 20 }}/>
            <div style={{ color: '#888888', fontFamily: 'Mitr', fontWeight: 400, fontSize: 35, marginTop: 10 }}>
              ¡Espera!
            </div>
            <div style={{ color: '#888888', fontFamily: 'Mitr', fontWeight: 400, fontSize: 24, marginTop: 15, textAlign: 'center', paddingRight: 20, paddingLeft: 20, lineHeight: '24px' }}>
              Te enviaremos de regreso a la aplicación...
            </div>
          </div>
          <div style={{ position: 'absolute', bottom: 10, left: 0, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src={LOGO_CLOUD_ICE} alt="Logo" style={{ width: 200, marginBottom: 5 }}/>
            <div style={{ color: '#888888', fontFamily: 'Mitr', fontWeight: 300, fontSize: 10, marginTop: 0, textAlign: 'center' }}>
              Designed & Developed by <label style={{ fontWeight: 500 }}>The Cloud Solution LLC.</label>
            </div>
            <div style={{ color: '#888888', fontFamily: 'Mitr', fontWeight: 300, fontSize: 10, marginTop: 0, textAlign: 'center' }}>
              Registered Trademark - All Rights Reserved
            </div>
          </div>
        </>
    );
  }
}

export default Flow;