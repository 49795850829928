import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  .scrollspy-panel {
    height: calc(100vh - 70px);
    position: fixed;
    top: 70px;
    right: 10px;
    width: 265px;
    border-left: 1px solid #dbdbdb;

    > ul {
      padding-left: 10.5px;
      padding-right: 10.5px;

      li {
        &::marker {
          color: blue;
        }
      }
    }

    .scrollspy-item {
      list-style: none;
      position: relative;

      &.scrollspy-item-level-1 {
        padding-left: 5px;
        border-bottom: 1px solid #dbdbdb;
        padding-bottom: 10px;
        margin-bottom: 15px;
        padding-top: 10px;
      }
  
      &.scrollspy-item-level-2 {
        padding-left: 15px;
        margin-bottom: 15px;

        > a {
          font-weight: unset;
        }
      }

      &.scrollspy-item-level-3 {
        padding-left: 30px;
        margin-bottom: 15px;

        > a {
          font-weight: unset;
        }
      }
  
      &.current-scrollspy-item {
        // list-style: disc;
        color: #009688 !important;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 2.5px);
          background: #009688;
          width: 6px;
          height: 6px;
          border-radius: 100px;
          left: -14px;
        }
        
        a {
          color: #009688 !important;
        }
      }
    }
  }

  .example-card-group {
    width: 100%;

    > div {
      border-left: 2px solid #E6ECF1;
      border-right: 2px solid #E6ECF1;
      border-top: 2px solid #E6ECF1;
      width: 100%;
      padding: 0px 15px 10px 15px;
    }

    > div:last-child {
      border-bottom: 2px solid #E6ECF1;
    }
  }

  .ksearchlogo-to-text {
    height: 25px;
    margin-bottom: -8px;
  }

  pre.language-java {
    > img {
      position: absolute;
      top: 0px;
      right: 3px;
      z-index: 1;
      height: 22px;
      width: 19px;
    }
  }

  pre.language-sql {
    > img {
      position: absolute;
      top: 1px;
      right: 3px;
      z-index: 1;
      height: 20px;
      width: 20px;
    }
  }

  code.language-java  {
    color: rgba(0,0,0,.7);
    
    .token.class-name,
    .token.function {
      color: rgba(0,0,0,.7);
    }

    .token.string {
      color: #2962ff;
    }

    .token.keyword {
      color: #880e4f;
    }
  }

  code.language-sql  {
    .token.keyword,
    .token.operator {
      color: #009688;
    }
  }

  .parameters-table {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;

    tr {
      td {
        text-align: center;
      }
    }
  }

  pre[class*="language-"]::before,
  pre[class*="language-"]::after {
    box-shadow: none;
  }

  pre[class*="language-"] > code {
    border-left: 10px solid #009688;
  }

  .k-text-align-center {
    text-align: center;
  }

  .k-display-none {
    display: none;
  }

  .k-display-block {
    display: block;
  }

  @media (min-width: 768px) {
    .k-text-align-right-md {
      text-align: right;
    }

    .k-display-block-md {
      display: block;
    }

    .k-display-none-md {
      display: none;
    }

    .k-display-flex-md {
      display: flex;
    }
  }

  @media (min-width: 992px) {
    .k-text-align-right-lg {
      text-align: right;
    }

    .k-display-block-lg {
      display: block;
    }

    .k-display-none-lg {
      display: none;
    }

    .k-display-flex-lg {
      display: flex;
    }
  }

  code.highlighted-sql {
    color: #009688;
    padding: 2px 4px 2px 4px;
  }

  code.highlighted-java {
    color: rgba(0,0,0,.7);
    padding: 2px 4px 2px 4px;
  }

  .a-link {
    color: #1a73e8;
    text-decoration: none;

    &:hover {
      color: #1a73e8;
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18.5px;
  }

  h3 {
    font-size: 15px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #333;
    font-weight: 500;
    font-family: 'Noto Sans JP', sans-serif;
    margin: 16px 0;
  }

  ul {
    li {
      font-family: 'Noto Sans JP', sans-serif;
      color: #495057;
      font-size: 14px;
      line-height: 25px;
    }
  }

  code.language-sql,
  code.language-java {
    font-size: 15px;
  }

  p {
    font-family: 'Noto Sans JP', sans-serif;
    color: #495057;
    font-size: 14px;
    line-height: 25px;
  }

  .p-inputtext,
  .p-link {
    font-family: 'Noto Sans JP', sans-serif;
  }

  html,
  body {
      height: auto;
      padding: 0;
      margin: 0;
      background-color: #F1EFEF;
  }
  body a {
    font-size: 12px;
    color: #495057;
    font-weight: 500;
    cursor: pointer;
    font-family: 'Noto Sans JP', sans-serif;

    &:hover {
        color: #009688;
    }
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  a {
    text-decoration: none;
  }
  button {
    cursor: pointer;
  }
  .ui-float-label {
    display: block;
    position: relative;
  }
  .p-grid {
    margin: 0;
  }
  .display-flex {
    display: flex;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .align-items-center {
    align-items: center;
  }
  .flex-direction-column {
    flex-direction: column;
  }
  /** **/
  .ui-dialog .ui-dialog-content {
    max-height: 90vh;
  }
  .no-padding {
    padding: 0!important;
  }
  
  
  
  
  /* Displays */
  .display-inline-block {
      display: inline-block;
  }
  /* Scrolling Horizontal */
  .scrolling-horizontal {
    overflow-x: auto;
    white-space: nowrap;
  }
  body {
    .ui-widget-overlay {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .symbol {
    border-radius: 0.42rem;
    text-align: center;
    background-color: #F3F6F9 !important;

    &.symbol-md {
      line-height: 40px;
      width: 40px;
      height: 40px;
    }
  }
  /* TEXT ALIGN */
  .text-align-center {
    text-align: center;
  }
  .text-align-right {
    text-align: right;
  }
  .text-align-left {
    text-align: left;
  }
  
  /* MARGIN */
  body {
    .ui-button {
      &.margin-left-sm {
        margin-left: 1px;
      }
      
      &.margin-left-md {
        margin-left: 2px;
      }
      
      &.margin-left-lg {
        margin-left: 4px;
      }
      
      &.margin-left-xl {
        margin-left: 8px;
      }
      
      &.margin-left-xxl {
        margin-left: 16px;
      }
      
      &.margin-right-sm {
        margin-right: 1px;
      }
      
      &.margin-right-md {
        margin-right: 2px;
      }
      
      &.margin-right-lg {
        margin-right: 4px;
      }
      
      &.margin-right-xl {
        margin-right: 8px;
      }
      
      &.margin-right-xxl {
        margin-right: 16px;
      }
      
      &.margin-top-sm {
        margin-top: 1px;
      }
      
      &.margin-top-md {
        margin-top: 2px;
      }
      
      &.margin-top-lg {
        margin-top: 4px;
      }
      
      &.margin-top-xl {
        margin-top: 8px;
      }
      
      &.margin-top-xxl {
        margin-top: 16px;
      }
      
      &.margin-bottom-sm {
        margin-bottom: 1px;
      }
      
      &.margin-bottom-md {
        margin-bottom: 2px;
      }
      
      &.margin-bottom-lg {
        margin-bottom: 4px;
      }
      
      &.margin-bottom-xl {
        margin-bottom: 8px;
      }
      
      &.margin-bottom-xxl {
        margin-bottom: 16px;
      }
    }
  }

  .margin-left-sm {
    margin-left: 1px;
  }

  .margin-left-md {
    margin-left: 2px;
  }

  .margin-left-lg {
    margin-left: 4px;
  }

  .margin-left-xl {
    margin-left: 8px;
  }

  .margin-left-xxl {
    margin-left: 16px;
  }

  .margin-right-sm {
    margin-right: 1px;
  }

  .margin-right-md {
    margin-right: 2px;
  }

  .margin-right-lg {
    margin-right: 4px;
  }

  .margin-right-xl {
    margin-right: 8px;
  }

  .margin-right-xxl {
    margin-right: 16px;
  }

  .margin-top-sm {
    margin-top: 1px;
  }

  .margin-top-md {
    margin-top: 2px;
  }

  .margin-top-lg {
    margin-top: 4px;
  }

  .margin-top-xl {
    margin-top: 8px;
  }

  .margin-top-xxl {
    margin-top: 16px;
  }

  .margin-bottom-sm {
    margin-bottom: 1px;
  }

  .margin-bottom-md {
    margin-bottom: 2px;
  }

  .margin-bottom-lg {
    margin-bottom: 4px;
  }

  .margin-bottom-xl {
    margin-bottom: 8px;
  }

  .margin-bottom-xxl {
    margin-bottom: 16px;
  }

  /* BORDERS */
  .border-bottom-light {
    border-bottom: 1px solid #e5e5e5;
  }
  /* FULL CONTAINER */
  .full-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 25px 0px 25px;
    display: block;
  }

  @media (min-width: 576px) {//sm
    .full-container {
      //max-width: 540px;
    }
  }

  @media (min-width: 768px) {//sm

    .full-container {
      //max-width: 620px;

      &.full-container-sidebar-opened {
        padding: 20px 50px 0px 310px;
        //max-width: 670px;
      }
    }
  }

  @media (min-width: 992px) {//sm
    .full-container {
      //max-width: 1240px;

      section {
        padding-top: 40px;
        margin-top: 25px;
        border-top: 1px solid #e5e5e5;
      }

      &.full-container-scrollspy {
        padding: 20px 300px 0px 25px;
      }

      &.full-container-sidebar-opened {
        //max-width: 1350px;
        //padding: 20px 50px 0px 310px;

        &.full-container-scrollspy {
          padding: 20px 300px 0px 310px;
        }
      }
    }
  }

  @media (min-width: 1200px) {//sm
    .full-container {
      //max-width: 1240px;

      &.full-container-scrollspy {
        padding: 20px 300px 0px 25px;
      }

      &.full-container-sidebar-opened {
        //max-width: 1350px;
        //padding: 20px 50px 0px 310px;

        &.full-container-scrollspy {
          padding: 20px 300px 0px 310px;
        }
      }
    }
  }

  @media (min-width: 1400px) {//sm
    .full-container {
      max-width: 1240px;

      &.full-container-scrollspy {
        padding: 20px 255px 0px 25px;
      }

      &.full-container-sidebar-opened {
        max-width: 1350px;
        padding: 20px 50px 0px 310px;

        &.full-container-scrollspy {
          padding: 20px 280px 0px 310px;
        }
      }
    }
  }

  /* BUTTONS */
  .btn {
    padding: 0px 0px;

    &:disabled {
      cursor: not-allowed !important;
    }

    &.btn-light-primary {
      &.light-style-1 {
        color: #3699ff;
        background-color: #e1f0ff;
        border-color: transparent;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #fff;
          background-color: #3699ff;
          border-color: transparent;
        }
      }
    }

    &.btn-dark-primary {
      &.dark-style-1 {
        color: #FFFFFF;
        background-color: #3699FF;
        border-color: #3699FF;
        text-transform: uppercase;
        font-size: 13px;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #FFFFFF;
          background-color: #187DE4;
          border-color: #187DE4;
        }
      }
    }

    &.btn-light-primary-inverse {
      &.light-style-1 {
        color: #b5b5c3;
        background: transparent;
        border-color: transparent;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #3699ff;
          background-color: #f3f6f9;
          border-color: transparent;
        }
      }
    }

    &.btn-light-danger {
      &.light-style-1 {
        color: #f64e60;
        background-color: #ffe2e5;
        border-color: transparent;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #fff;
          background-color: #f64e60;
          border-color: transparent;
        }
      }
    }

    &.btn-dark-danger {
      &.dark-style-1 {
        color: #ffffff;
        background-color: #F64E60;
        border-color: #F64E60;
        text-transform: uppercase;
        font-size: 13px;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #ffffff;
          background-color: #EE2D41;
          border-color: #EE2D41;
        }
      }
    }

    &.btn-light-black {
      &.light-style-1 {
        color: #212121;
        background-color: #d6d6e0;
        border-color: transparent;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #fff;
          background-color: #212121;
          border-color: transparent;
        }
      }
    }

    &.btn-dark-black {
      &.dark-style-1 {
        color: #ffffff;
        background-color: #181C32;
        border-color: #181C32;
        text-transform: uppercase;
        font-size: 13px;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #ffffff;
          background-color: #131628;
          border-color: #131628;
        }
      }
    }

    &.btn-light-gray {
      &.light-style-1 {
        color: #9e9e9e;
        background-color: #eeeeee;
        border-color: transparent;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #fff;
          background-color: #9e9e9e;
          border-color: transparent;
        }
      }
    }

    &.btn-dark-gray {
      &.dark-style-1 {
        color: #3F4254;
        background-color: #E4E6EF;
        border-color: #E4E6EF;
        text-transform: uppercase;
        font-size: 13px;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #3F4254;
          background-color: #d7dae7;
          border-color: #d7dae7;
        }
      }
    }

    &.btn-light-purple {
      &.light-style-1 {
        color: #8950fc;
        background-color: #eee5ff;
        border-color: transparent;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #fff;
          background-color: #8950fc;
          border-color: transparent;
        }
      }
    }

    &.btn-dark-purple {
      &.dark-style-1 {
        color: #ffffff;
        background-color: #8950FC;
        border-color: #8950FC;
        text-transform: uppercase;
        font-size: 13px;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #ffffff;
          background-color: #7337EE;
          border-color: #7337EE;
        }
      }
    }

    &.btn-light-success {
      &.light-style-1 {
        color: #1bc5bd;
        background-color: #c9f7f5;
        border-color: transparent;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #fff;
          background-color: #1bc5bd;
          border-color: transparent;
        }
      }
    }

    &.btn-dark-success {
      &.dark-style-1 {
        color: #ffffff;
        background-color: #1BC5BD;
        border-color: #1BC5BD;
        text-transform: uppercase;
        font-size: 13px;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #ffffff;
          background-color: #0BB7AF;
          border-color: #0BB7AF;
        }
      }
    }

    &.btn-light-warning {
      &.light-style-1 {
        color: #ffa800;
        background-color: #fff4de;
        border-color: transparent;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #fff;
          background-color: #ffa800;
          border-color: transparent;
        }
      }
    }

    &.btn-dark-warning {
      &.dark-style-1 {
        color: #ffffff;
        background-color: #FFA800;
        border-color: #FFA800;
        text-transform: uppercase;
        font-size: 13px;

        &.focus:not(.btn-text),
        &:active:not(.btn-text),
        &:hover:not(.btn-text) {
          color: #ffffff;
          background-color: #EE9D01;
          border-color: #EE9D01;
        }
      }
    }

    &:not(.btn-text) {
      cursor: pointer;
    }

    &.btn-icon-left {
      padding-left: 20px;

      svg {
        position: absolute;
        left: 10px;
        top: calc(50% - 6px);
        font-size: 12px;
      }
    }
  }

  button {
      box-shadow: none !important;

      &.ui-button-text-empty {
          width: 25px;
          height: 25px;

          i {
              font-size: 13px;
          }

          .ui-button-text {
              display: none;
          }
      }
	}
	
	/* LABELS */
	.label {
		padding: 5px 11px;
		border-radius: 6px;

		&.label-lg {
			font-size: 11.5px;
		}

		&.label-light-primary {
			color: #3699ff;
			background-color: #e1f0ff;
			border-color: transparent;
		}

		&.label-light-danger {
			color: #f64e60;
			background-color: #ffe2e5;
			border-color: transparent;
		}
	}

  .p-button {
    .p-button-label {
      padding: .429em 1em;
    }

    &.p-button-filter {
      span {
        font-size: 14px;
      }
    }
  }

  /** Switch Group **/
	body {
    .zlab-switch {
        margin-top: 12px;
    
        .ui-float-label {
            p-inputswitch {
                .ui-inputswitch {
                    .ui-inputswitch-slider {
                        box-shadow: none;
                        background-color: #ecf0f3;
                    }

                    &:hover {
                        .ui-inputswitch-slider {
                            background-color: #ecf0f3;
                        }
                    }

                    &.ui-inputswitch-checked {
                        .ui-inputswitch-slider {
                            &::before {
                                background-color: #3699ff;
                            }
                        }
                    }
                }

                ~ label-field {
                    position: absolute;
                    left: 0;
                    margin-top: -0.5em;
                    top: -15px;
        
                    > label {
                        font-size: 12px;
                        color: #757575;
        
                        &.label-field-mandatory {
                            &::after {
                                content: ' *';
                                color: #f64e60;
                            }
                        }
                    }
                }
            }
        }
    }
	}

	/** Input Group **/
	.zlab-input-full-expand {
    margin-top: 12px;

    .ui-float-label {
        > input {
            width: 100%;
            font-size: 13px;
            font-family: "Poppins";
            border: 1px solid #e2e5ec;
    
            &:enabled:hover:not(.ui-state-error) {
                border-color: #e2e5ec;
            }

            &:enabled:focus:not(.ui-state-error) {
                border-color: #69b3ff;
            }

            ~ label-field {
                position: absolute;
                left: 0;
                margin-top: -0.5em;
                top: -15px;

                > label {
                    font-size: 12px;
                    color: #757575;

                    &.label-field-mandatory {
                        &::after {
                            content: ' *';
                            color: #f64e60;
                        }
                    }
                }
            }
        }
    }

    &.zlab-input-inline-editing {
        margin-bottom: 10px;

        .inline-editing-plugin {
            text-align: right;
            position: absolute;
            width: 100%;
            bottom: -28px;

            > i.icon-edit {
                position: absolute;
                top: -52px;
                right: 10px;
                display: none;
                cursor: pointer;
                color: gray;
            }

            p-progressspinner {
                position: absolute;
                top: -57px;
                right: 7px;

                > div {
                    width: 25px;
                    height: 25px;
                }
            }

            .inline-editing-plugin-actions-container {
                > button {
                    margin-right: 3px;
                }
            }
        }

        &:hover {
            input:not(.zlab-state-editing) {
                ~ .inline-editing-plugin {
                    > i.icon-edit {
                        display: inherit;
                    }
                }
            }
        }
        
        .ui-float-label {
            > input:not(.zlab-state-editing):not(.zlab-state-loading) {
                &:hover {
                    ~ .inline-editing-plugin {
                        > i.icon-edit {
                            display: inherit;
                        }
                    }
                }
            }

            > label {
                top: -.75em;
                font-size: 12px;
            }
        }
    }
	}
	/* Textarea Group */
	.zlab-textarea-full-expand {
    margin-top: 12px;

    .ui-float-label {
        > textarea {
            width: 100%;
            font-size: 13px;
            font-family: "Poppins";
            border: 1px solid #e2e5ec;
            font-weight: normal;
            padding: 0.429em;
            border-radius: 3px;
            resize: none;
            height: 94px;
    
            &:enabled:hover:not(.ui-state-error) {
                border-color: #e2e5ec;
            }

            &:enabled:focus:not(.ui-state-error) {
                border-color: #69b3ff;
            }

            ~ label-field {
                position: absolute;
                left: 0;
                margin-top: -0.5em;
                top: -15px;
    
                > label {
                    font-size: 12px;
                    color: #757575;
    
                    &.label-field-mandatory {
                        &::after {
                            content: ' *';
                            color: #f64e60;
                        }
                    }
                }
            }
        }
    }

    &.zlab-textarea-inline-editing {
        margin-bottom: 10px;

        .inline-editing-plugin {
            text-align: right;
            position: absolute;
            width: 100%;
            bottom: -28px;

            > i.icon-edit {
                position: absolute;
                top: -54px;
                right: 8px;
                display: none;
                cursor: pointer;
                color: gray;
            }

            p-progressspinner {
                position: absolute;
                top: -59px;
                right: 7px;

                > div {
                    width: 25px;
                    height: 25px;
                }
            }

            .inline-editing-plugin-actions-container {
                > button {
                    margin-right: 3px;
                }
            }
        }

        &:hover {
            textarea:not(.zlab-state-editing) {
                ~ .inline-editing-plugin {
                    > i.icon-edit {
                        display: inherit;
                    }
                }
            }
        }
        
        .ui-float-label {
            > input:not(.zlab-state-editing):not(.zlab-state-loading) {
                &:hover {
                    ~ .inline-editing-plugin {
                        > i.icon-edit {
                            display: inherit;
                        }
                    }
                }
            }

            > label {
                top: -.75em;
                font-size: 12px;
            }
        }
    }
	}
	/** Select Button **/
	.zlab-select-button-full-expand {
    margin-top: 12px;

    .ui-float-label  {
        > p-selectbutton {
            display: flex;

            ~ label {
                top: -.75em;
                font-size: 12px;
            }

            .ui-selectbutton {
                flex: 1;
                display: flex;

                > div {
                    flex: 1;
                    background-color: #f3f6f9;
                    color: #80808f;
                    border: none;
                    border: 1px solid #e2e5ec;
                    height: 33px;

                    &.ui-button:not(.p-disabled):not(.p-active).p-focus {
                        box-shadow: 0 0 0 0 transparent;
                    }

                    &.ui-button:not(.p-disabled):not(.p-active):hover {
                        background-color: #e2e5ec;
                        border: 1px solid #e2e5ec;

                        span {
                            color: #333333;
                            padding: 5px 1em;
                        }
                    }

                    &.ui-button:not(.p-disabled).p-active {
                        background-color: #3699ff;
                        border: 1px solid #3699ff;

                        span {
                            color: white;
                            padding: 5px 1em;
                        }
                    }
                }
            }

            ~ label-field {
                position: absolute;
                left: 0px;
                margin-top: -0.5em;
                top: -15px;
    
                > label {
                    font-size: 12px;
                    color: #757575;
    
                    &.label-field-mandatory {
                        &::after {
                            content: ' *';
                            color: #f64e60;
                        }
                    }
                }
            }
        }

        .inline-editing-plugin {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;

            p-progressspinner {
                position: absolute;
                top: 5px;
                left: calc(50% - 12.5px);

                > div {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
	}

	/** Input **/
	body .p-inputtext:enabled:focus:not(.ui-state-error) {
		box-shadow: 0 0 0 0 white;
	}

	/* Checkbox */
	body {
    .p-checkbox {
        width: 15px;
        height: 15px;

        .p-checkbox-box {
            border-radius: 3px;
            width: 15px;
            height: 15px;
            background-color: #ecf0f3;
            border: 1px solid transparent;

            &.p-active {
                border-color: #3699ff;
                background-color: #3699ff;
            }

            .p-checkbox-icon {
                font-size: 13px;
            }
        }
    }
	}

	::-webkit-input-placeholder { /* Edge */
    color: rgba(127, 127, 127, 0.65);
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(127, 127, 127, 0.65);
	}

	::placeholder {
    color: rgba(127, 127, 127, 0.65);
	}
	/* Columnas movibles*/
	td {
    i.movable {
        cursor: move;
    }
	}
	/**/
	.helper-message-container {
    margin-top: 3px;

    > div {
        font-size: 11px;
    }
	}
	/* DROPDOWN */
	.p-dropdown {
		border: 1px solid #e2e5ec;
		height: auto;
		min-width: 1px;

		.p-dropdown-label {
			font-size: 14px;
			padding-right: 0;
		}

		&:not(.p-disabled) {
				&.p-focus {
						box-shadow: none;
						border-color: #3699ff;
				}

				&:hover {
						border-color: #3699ff;
				}
		}

		.p-dropdown-trigger {
			width: 2em;

			span {
				font-size: 11px;
			}
		}     
	}
  .k-principal-table {
    padding: 25px;
    background: white;
    -webkit-box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
    box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
    border: 0;
    border-radius: .42rem;
    position: relative;

    .message-table {
        padding: 30px;
        text-align: center;
    }

    .k-loading-principal-table {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      left: 0;
      z-index: 99;
      background: rgba(0, 0, 0, 0.1);

      .k-loader-principal-table {
        border-radius: 50%;
        perspective: 800px;
        height: 25px;
        width: 25px;
        position: absolute;
        top: 7px;
        right: 7px;
    
        .inner {
            position: absolute;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            border-radius: 50%; 
            
            &.one {
                left: 0%;
                top: 0%;
                animation: rotate-one 1s linear infinite;
                border-bottom: 3px solid #22b9ff;
            }
    
            &.two {
                right: 0%;
                top: 0%;
                animation: rotate-two 1s linear infinite;
                border-right: 3px solid black;
            }
    
            &.three {
                right: 0%;
                bottom: 0%;
                animation: rotate-three 1s linear infinite;
                border-top: 3px solid #f57f17;
            }
        }
      }
    }

    .block-k-principal-table {
      p-progressspinner {
          position: absolute;
          top: calc(50%);
          left: calc(50% - 25px);
          width: 50px;
          height: 50px;
  
          .ui-progress-spinner {
              width: 50px;
              height: 50px;
          }
      }
    }

    .p-sortable-column {
      &:not(.p-highlight):hover {
        background: transparent;
      }
      &.p-highlight:hover {
        background: transparent;
      }

      .p-sortable-column-icon {
        font-size: 12px;
      }
    }

    div.p-datatable-wrapper {
      > table {
				> thead.p-datatable-thead {
						> tr {
								> th {
										font-size: 11px;
										color: #b5b5c3;
										letter-spacing: 1.3px;
										text-transform: uppercase;
										border: none;
										background: white;
										text-align: left;
										padding: 10px 10px;
										font-weight: 600;

										.th-label {
												display: inline-block;

												&:not(.th-order-available) {
														cursor: not-allowed;
												}
										}

										&.text-align-center {
												text-align: center;
										}
										
										&.text-align-right {
												text-align: right;
										}
										
										&.text-align-left {
												text-align: left;
										}

										&.ordering {
												color: #22b9ff;
										}

										&.th-order-available {
												.th-label {
														cursor: pointer;
												}
										}
								}
						}
				}

				tbody.p-datatable-tbody {
						> tr {
								background: white;
								border-top: 1px solid #ecf0f3;

								> td {
										border: none;
										padding: 10px 10px;

										&.text-align-center {
											text-align: center;
										}

										.icon-table-small {
												background-color: transparent;
												background-image: url(https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5b3a562cd38a522e77ff7948/dd4d1ceb-1f4e-4622-86ac-0969a5c4d9dc/128?size=48&s=48);
												background-position: center center;
												background-repeat: no-repeat;
												background-size: cover;
												border-radius: 50%;
												display: flex;
												width: 40px;
												height: 40px;
										}
								}

								&:nth-last-child(1) {
										border-bottom: 1px solid #ecf0f3;
								}
						}
				}
      }
		}

		div.p-datatable-footer {
			background: white;
			border: none;
			padding: 0;
			margin: 0;

			.k-paginator {
				.p-paginator {
					padding: 0;

					.p-paginator-pages {
						.p-paginator-page {
							height: 2.286em;
							min-width: 2.286em;
							border-radius: 4px;
							font-size: 11.5px;
							margin: 0 5px 0 0;

							&.p-highlight {
								background-color: #3699ff;
								color: #fff;
							}
							
							&:focus {
								box-shadow: none;
							}

							&:hover:not(.p-disabled) {
								background-color: #3699ff;
								color: #fff; 
							}
						}
					}

					.p-paginator-next {
						border-radius: 4px;
						font-size: 11.5px;
						margin-right: 5px;
						background-color: #ecf1f6;
						height: 2.286em;
						min-width: 2.286em;
						margin: 0 5px 0 0;

						.p-paginator-icon {
							font-size: 1.25em;
						}

						&:focus {
							box-shadow: none;
						}

						&:hover:not(.p-disabled) {
							background-color: #3699ff;
							color: #fff; 
						}
					}

					.p-paginator-last {
						border-radius: 4px;
						font-size: 11.5px;
						background-color: #ecf1f6;
						height: 2.286em;
						min-width: 2.286em;
						margin: 0 5px 0 0;

						.p-paginator-icon {
							font-size: 1.25em;
						}

						&:focus {
							box-shadow: none;
						}

						&:hover:not(.p-disabled) {
							background-color: #3699ff;
							color: #fff; 
						}
					}

					.p-paginator-first {
						border-radius: 4px;
						font-size: 11.5px;
						margin-right: 5px;
						background-color: #ecf1f6;
						height: 2.286em;
						min-width: 2.286em;
						margin: 0 5px 0 0;

						.p-paginator-icon {
							font-size: 1.25em;
						}

						&:focus {
							box-shadow: none;
						}
						
						&:hover:not(.p-disabled) {
							background-color: #3699ff;
							color: #fff; 
						}
					}

					.p-paginator-prev {
						border-radius: 4px;
						font-size: 11.5px;
						margin-right: 5px;
						background-color: #ecf1f6;
						height: 2.286em;
						min-width: 2.286em;
						margin: 0 5px 0 0;

						.p-paginator-icon {
							font-size: 1.25em;
						}

						&:focus {
							box-shadow: none;
						}

						&:hover:not(.p-disabled) {
							background-color: #3699ff;
							color: #fff; 
						}
					}
			}

			.p-dropdown {
				background: #ecf1f6;
				border-radius: 6px;
				margin-right: 8px;
				border: none;

				.p-dropdown-label {
					background: transparent;
					color: #80808f;
				}

				.p-dropdown-trigger {
					background: transparent;

					.p-dropdown-trigger-icon {
						color: #80808f;
					}
				}

				&:hover,
				&:active {
					color: #fff;
					background-color: #3699ff;

					.p-dropdown-label {
						color: white;
					}

					.p-dropdown-trigger {
						.p-dropdown-trigger-icon {
							color: white;
						}
					}
				}
			}
		}
	}
  }

  .criteria-section {
        > div {
          display: inline-block;
        }

        button {
            margin-right: 10px;
    
            &.p-filter-removible {
                padding-right: 15px;
    
                .icon-remove-filter {
                    position: absolute;
                    right: 9px;
                    top: calc(50% - 7.5px);
                    color: #9e9e9e;
                    font-size: 14px;
                }
    
                &:hover,
                &:active,
                &.focus {
                    .icon-remove-filter {
                        color: white;
                    }
                }
            }
    
            span {
                max-width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
            }
        }
    }

    .overlay-list-criteria {
      border-radius: 5px !important;
      border: none !important;
      box-shadow: 0 2px 5px 0 rgba(82, 63, 105, 0.2) !important;
      z-index: 13003 !important;
  
      .p-overlaypanel-content {
          padding: 0 !important;
      }
  }

  .overlay-text-criteria {
    border-radius: 5px !important;
    border: none !important;
    box-shadow: 0 2px 5px 0 rgba(82, 63, 105, 0.2) !important;

    .p-overlaypanel-content {
        padding: 0 !important;
        width: 230px;

        .body-text-criteria-container {
            padding: 7px;
            border-bottom: 1px solid #eaeaea;
            width: 100%;
            position: relative;

            input {
                width: 150px;
                border: none;
                outline: none;
                padding: 0.429em;
                padding-right: 2em;
                height: 31px;
                width: 100%;
            }

            span {
                color: #eaeaea;
                position: absolute;
                top: 50%;
                left: auto;
                margin-top: -0.5em;
                right: 0.5em;
            }
        }

        .footer-text-criteria-container {
            padding: 5px 0px;
        }
    }
}

    /* Listbox */
    .p-listbox {
        border: none;

        .p-listbox-header {
          padding: 7px;
          background: white;
          border-bottom: 1px solid #eaeaea;

            .p-listbox-filter-container {
                input {
                  border: none;
                  font-family: 'Poppins';
                }

                .p-listbox-filter-icon {
                  color: #eaeaea;
                }
            }
        }

        .p-listbox-list-wrapper {
            max-height: 300px;

            .p-listbox-list {
                padding: 10px 0px;

                .p-listbox-item {
                    padding: 5px 18px;
                    background-color: transparent;

                    &:hover {
                      background-color: #fafafa;
                    }

                    &:focus {
                      box-shadow: none;
                    }

                    .p-listbox-item-multiple {
                      padding-left: 25px;

                      svg {
                        position: absolute;
                        top: 11px;
                        left: 24px;
                        font-size: 14px;
                      }

                      span {
                        font-size: 14px;
                      }
                    }
                }
            }
        }

        &:not(.p-disabled) {
            .p-listbox-item {
                &:not(.p-highlight):not(.p-disabled):hover {
                    background-color: #fafafa;
                }
            }
        }
    

    &.p-listbox-xl {
        .ui-listbox {
            width: 400px;
        }
    }

    &.p-listbox-full {
        .ui-listbox {
            width: 100%;
        }
    }

    &.p-listbox-height-static {
        .ui-listbox {
            .ui-listbox-list-wrapper {
                min-height: 300px;
            }
        }
    }
}

`;