import React from 'react'
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';
import GlobalStyles from "./pages/styles/global";
import { Provider } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faBinoculars, faUsers, faBook, faCogs, faSignOutAlt, faClipboardList, faIdCard, faFilter, faTimesCircle, faCheckSquare, faBars } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import store from './redux/store';

library.add(faBinoculars, faUsers, fab, faBook, faCogs, faSignOutAlt, faClipboardList, faIdCard, faFilter, faTimesCircle, faSquare, faCheckSquare, faBars);

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Routes></Routes>
      <GlobalStyles />
    </BrowserRouter>
  </Provider>
)

export default App