import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import Windows from '../../constants/Windows';

class Root extends Component {

  render() {
    return <Redirect to={Windows.HOME_WHAT_IS} />
  }
}

export default Root;