import { type as updateIsMobile } from "../actions/updateIsMobile";

function reducer(state = false, { type, payload }) {
    switch (type) {
        case updateIsMobile: {
            return payload;
        }

        default:
            return state;
    }
}

export default reducer;