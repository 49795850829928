import { type as updateVisibleSidebar } from "../actions/updateVisibleSidebar";

function reducer(state = false, { type, payload }) {
    switch (type) {
        case updateVisibleSidebar: {
            return payload;
        }

        default:
            return state;
    }
}

export default reducer;